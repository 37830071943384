import Login from '@/pages/Login/Login';
import ForgotPassword from '@/pages/Login/ForgotPassword';
import ForgotSent from '@/pages/Login/ForgotSent';
import SettingPassword from '@/pages/Login/SettingPassword';
import CreatePassword from '@/pages/Login/CreatePassword';

import Layout from '@/components/Layout/Layout';
import Top from '@/pages/Reports/Top';
import IntegrateYoutube from '@/pages/Youtube/IntegrateYoutube';
import IntegrateYoutubeRegister from '@/pages/Youtube/IntegrateYoutubeRegister'
import ClientList from '@/pages/Client/ClientList';
import Add from '@/pages/Video/Form';
import VideoEdit from '@/pages/Video/FormEdit';
import VideoCheck from '@/pages/Video/VideoCheck';
import AddComplete from '@/pages/Video/FormSubmit';
import User from '@/pages/Users/User';
import WhitepaperSubscribersList from '@/pages/Client/WhitepaperSubscribersList';
import ClientUserList from '@/pages/Client/ClientUserList';
// import Project from "@/pages/Project/List";
import VideoCheckEdit from "@/pages/Project/VideoCheck";
import TopMarketResearch from "@/pages/Reports/TopMarketResearch"
import TopCompareStep3 from "@/pages/Reports/TopCompareStep3";
import Tableau from "@/pages/Reports/Tableau.vue";


export const web_router = [{
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiredRoles: [],
            title: 'ログイン'
        },

    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            requiredRoles: [],
            title: 'パスワードをお忘れの場合'
        }
    },
    {
        path: '/forgot-sent',
        name: 'sent-email-forgot-password',
        component: ForgotSent,
        meta: {
            requiredRoles: [],
            title: 'メールの送信が完了しました。'
        }
    },
    {
        path: '/reset-password',
        name: 'setting-password',
        component: SettingPassword,
        meta: {
            requiredRoles: [],
            title: 'メールの送信が完了しました。'
        }
    },
    {
        path: '/create-password',
        name: 'create-password',
        component: CreatePassword,
        meta: {
            requiredRoles: [],
            title: 'パスワード新規発行'
        }
    },
    {
        path: '/',
        redirect: '/',
        meta: {
            humanName: 'Animes',
            showOnNav: true
        },

        name: 'Layout',
        component: Layout,
        children: [{
                path: '/',
                name: 'home',
                component: Top,
                meta: {
                    requiredRoles: ['admin', 'buyer.private', 'buyer.free', 'buyer.advance'],
                    title: 'レポート'
                }
            },
            {
                path: '/top-compare',
                name: 'top-compare',
                component: () => import('@/pages/Reports/TopCompare'),
                meta: {
                    requiredRoles: ['admin', 'buyer.private', 'buyer.free', 'buyer.advance'],
                    title: 'レポート'
                }
            },
            {
                path: '/top-compare-step-1',
                name: 'top-compare-step-1',
                component: () => import('@/pages/Reports/TopCompareStep1'),
                meta: {
                    requiredRoles: ['admin', 'buyer.private', 'buyer.free', 'buyer.advance'],
                    title: 'レポート'
                }
            },
            {
                path: '/forgot-password',
                name: 'forgot-password',
                component: ForgotPassword,
                meta: {
                    requiredRoles: [],
                    title: 'パスワードをお忘れの場合'
                }
            },
            {
                path: '/forgot-sent',
                name: 'sent-email-forgot-password',
                component: ForgotSent,
                meta: {
                    requiredRoles: [],
                    title: 'メールの送信が完了しました。'
                }
            },
            {
                path: '/reset-password',
                name: 'setting-password',
                component: SettingPassword,
                meta: {
                    requiredRoles: [],
                    title: 'メールの送信が完了しました。'
                }
            },

            {
                path: '/integrate-youtube',
                name: 'integrate-youtube',
                component: IntegrateYoutube,
                meta: {
                    requiredRoles: ['admin', 'buyer.private', 'buyer.spot', 'buyer.market', 'buyer.advance'],
                    title: 'YouTube連携'
                }
            },
            {
                path: '/integrate-youtube/register',
                name: 'integrate-youtube-register',
                component: IntegrateYoutubeRegister,
                meta: {
                    requiredRoles: ['buyer.private', 'buyer.advance'],
                    title: 'YouTube連携登録'
                }
            },
            {
                path: '/users',
                name: 'users',
                component: User,
                meta: {
                    requiredRoles: ['admin', 'buyer'],
                    title: 'ユーザー情報'
                }
            },
            {
                path: '/top-detail/:video_id',
                name: 'top-detail',
                component: () => import('@/pages/Reports/TopDetail') ,
                meta: {
                    requiredRoles: ['admin', 'buyer'],
                    title: 'レポート'
                }
            },
            {
                path: '/top-detail-step-1/:video_id',
                name: 'top-detail-step-1',
                component: () => import('@/pages/Reports/TopDetailStep1') ,
                meta: {
                    requiredRoles: ['admin', 'buyer'],
                    title: 'レポート'
                }
            },
            {
                path: '/clients',
                name: 'clients',
                component: ClientList,
                meta: {
                    requiredRoles: ['admin'],
                    title: 'クライアント一覧'
                }
            },
            {
                path: '/add',
                name: 'add',
                component: Add,
                meta: {
                    requiredRoles: ['admin', 'buyer'],
                    title: 'CM動画追加'
                }
            },
            {
                path: '/video-edit/:video_id',
                name: 'video-edit',
                component: VideoEdit,
                meta: {
                    requiredRoles: ['admin', 'buyer'],
                    title: '動画編集'
                }
            },
            {
                path: '/video-check',
                name: 'video-check',
                component: VideoCheck,
                meta: {
                    requiredRoles: ['admin', 'buyer'],
                    title: '動画確認'
                }
            },
            {
                path: '/video-complete',
                name: 'video-complete',
                component: AddComplete,
                meta: {
                    requiredRoles: ['admin', 'buyer'],
                    title: '動画登録確認'
                }
            },

            {
                path: '/whitepaper-subscribers',
                name: 'whitepaper-subscribers',
                component: WhitepaperSubscribersList,
                meta: {
                    requiredRoles: ['admin'],
                    title: '資料DLクライアント一覧'
                }
            },
            {
                path: '/clients/:id/users',
                name: 'client_users',
                component: ClientUserList,
                meta: {
                    requiredRoles: ['admin'],
                    title: 'ユーザー一覧'
                }
            },
            // {
            //     path: '/project',
            //     name: 'project',
            //     component: Project,
            //     meta: {
            //         requiredRoles: ['admin', 'buyer'],
            //         title: '案件一覧'
            //     }
            // },
            {
                path: '/project/:project_id/video/:video_id/video-check',
                name: 'project-video-check',
                component: VideoCheckEdit,
                meta: {
                    requiredRoles: ['admin', 'buyer'],
                    title: '動画確認'
                }
            },
            {
                path: '/market-research',
                name: 'market-research',
                component: TopMarketResearch,
                meta: {
                    requiredRoles: ['admin','buyer.free','buyer.private', 'buyer.advance'],
                    title: 'マーケットリサーチ'
                }
            },
            {
                path: '/top-compare-step-3',
                name: 'top-compare-step-3',
                component: TopCompareStep3,
                meta: {
                    requiredRoles: ['admin', 'buyer.private', 'buyer.free', 'buyer.advance'],
                    title: '放映検証(事後)'
                }
            },
            {
                path: '/tableau-report',
                name: 'tableau-report',
                component: Tableau,
                meta: {
                    requiredRoles: ['admin', 'buyer'],
                    title: 'TARGET'
                }
            },
        ],
    },
];